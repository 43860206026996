<template>
    <tablecustom 
        @search="searchB" 
        :columns="columns" 
        :rows="rows" 
        @open="open"
        :datafilter="filterAll"
        :showpag="true"
        :pages="this.pages"
        :objParams="this.objPages"
        @change="change" 
        @clearFilter="clearFilter"
        @getdata="this.getdata"
        @changelimit="this.changelimit"
    />
</template>

<script>
import viewCheck from '@/views/accounts/allAccounts/viewnew/blocks/activity/checks/viewCheck.vue';
import tablecustom from '@/components/globaltable/index.vue';

let apiDocs = new Documents()

export default {
    props: ['client', 'obj'],
    components: {
        viewCheck,
        tablecustom,
        files
    },
    data() {
        return {
            showTable: false,
            objPages: {
                page: "1",
                pagelimit: "10",
                companies: "",
                workerId: "",
                time: "",
                page: ''
            },
            rows: [],
            columns: [
                {
                    name: "ID",
                    text: "reportId",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("Employee"),
                    text: "",
                    align: "left",
                    status: true,
                    mutate: (item, obj) => this.workerMutate(item, obj)
                },
                {
                    name: this.$t("date_time"),
                    text: "datetime",
                    align: "left",
                    status: true,
                }
            ],
            showCheck: false,
            check: {},
            pages: {}
        }
    },
    created() {

    },
    mounted() {
        this.getdata()
    },
    methods: {
        changelimit(limit) {
            this.objPages.pagelimit = limit
            this.getdata()
        },
        workerMutate(item, obj) {
            return obj.worker.workerName
        },
        getdata(page) {
            if(page) {
                this.objPages.page = page
            }
            this.objPages.companies = []
            this.objPages.companies.push(this.obj.companyId)

            this.objPages.companies = JSON.stringify(this.objPages.companies)

            apiDocs.getChecks(this.objPages).then(result => {
                if(result.status === 'done') {
                    this.rows = result.data.reports
                    this.pages.links = result.data.links
                    this.pages.total = result.data.total
                } else {
                    // todo logs
                }
            })
        },
        open(e) {
            apiDocs.getCheck(e.reportId).then(result => {
                if(result.status === 'done') {
                    this.check = result.data
                    this.showCheck = true
                } else {
                    // todo logs
                }
            })
        }
    }
};
</script>